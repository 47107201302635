import { setPopup, showPopup } from "./popup.js";
import { fadeInElement, fadeOutElement, slideInListElements } from "./visuals.js";

import * as images from 'url:../img/services/*.jpg';
import paw from '../img/paw.svg';
import services from '../content/services.json';

// Cache DOM queries and templates
const servicesWrapperEl = document.querySelector('.services__wrapper');
const serviceTemplate = document.createElement('template');

// Constants for timing to improve maintainability
const FADE_OUT_DURATION = 500;
const FADE_IN_DURATION = 800;
const COLLAPSE_DURATION = 200;

// Precompile templates for better performance
const createListItemHTML = (description) => `
    <li class="service__list-item">
        <img class="service__list-icon" src="${paw}" alt="paw" loading="lazy" aria-hidden="true">
        <h3 class="service__list-desc subtitle">${description}</h3>
    </li>`;

const createExpandedListItemHTML = (description) => `
    <li class="service__expanded__list-item">
        <img class="service__expanded__list-icon" src="${paw}" alt="paw" loading="lazy" aria-hidden="true">
        <p class="service__expanded__list-desc text">${description}</p>
    </li>`;

const createPriceHTML = (price) => `
    <p class="service__expanded-price subtitle">${price}</p>`;

export function setServices() {
    loadServicesElements();
}

export async function loadServiceLong(element) {
    if (element.classList.contains('disabled')) return;
    
    const wrapper = element.querySelector('.service__wrapper');
    fadeOutElement(wrapper);
    element.classList.add('service__expanded', 'disabled');

    setTimeout(() => {
        const serviceData = services[element.getAttribute('serviceName')].long;
        
        // Create expanded service content using DocumentFragment for better performance
        const fragment = document.createDocumentFragment();
        const contentDiv = document.createElement('div');
        contentDiv.className = 'service__content';
        
        // Build HTML string once instead of multiple DOM operations
        contentDiv.innerHTML = `
        <div class="service__wrapper invisible">
            <div class="service__expanded__headline">

                    <picture class="service__expanded__headline-picture">
                        <source 
                            srcset="${images[element.getAttribute('serviceName')]}?as=webp"
                            type="image/webp">
                        <img class="service__expanded__headline-picture-img"
                             src="${images[element.getAttribute('serviceName')]}" 
                             alt="${serviceData.alt}" 
                             loading="lazy">
                    </picture>

                <div class="service__expanded__headline-description">
                    <h3 class="service__expanded__title subtitle">${serviceData.name}</h3>
                    <div class="service__expanded__subheadline">
                        <p class="service__expanded__text text">${serviceData.shortDescription}</p>
                    </div>
                </div>
                <div class="service__expanded__headline-price price-normal">
                    <div class="service__expanded__price-title">
                        <i class="service__expanded-price-icon fa-solid fa-coins" aria-hidden="true"></i>
                        <p class="service__expanded-price subtitle bold">CENNIK</p>
                        <i class="service__expanded-price-icon fa-solid fa-coins" aria-hidden="true"></i>
                    </div>
                    ${serviceData.price.map(createPriceHTML).join('')}
                </div>
            </div>
            <ul class="service__expanded__list">
                ${serviceData.description.map(createExpandedListItemHTML).join('')}
            </ul>
            <div class="service__expanded__headline-price price-mobile">
                <div class="service__expanded__price-title">
                    <i class="service__expanded-price-icon fa-solid fa-coins" aria-hidden="true"></i>
                    <p class="service__expanded-price subtitle bold">CENNIK</p>
                    <i class="service__expanded-price-icon fa-solid fa-coins" aria-hidden="true"></i>
                </div>
                ${serviceData.price.map(createPriceHTML).join('')}
            </div>
            <div class="service__expanded_buttons">
                <button onclick="location.href='#contact'" class="service__expanded__btn btn__small btn">KONTAKT</button>
                <button class="service__expanded__btn btn__small btn">ZWIŃ</button>
            </div>
        </div>`;
        
        fragment.appendChild(contentDiv);
        element.innerHTML = '';
        element.appendChild(fragment);
        
        const listEls = element.querySelectorAll('.service__expanded__list-item');
        slideInListElements(listEls);
    }, FADE_OUT_DURATION);

    setTimeout(() => {
        element.classList.add('service__expanded-loaded');
        fadeInElement(element.querySelector('.service__wrapper'));
    }, FADE_IN_DURATION);
}

export function loadServiceShort(element) {
    if (element.classList.contains('disabled')) return;
    
    const wrapper = element.querySelector('.service__wrapper');
    fadeOutElement(wrapper);
    element.classList.add('disabled');

    setTimeout(() => {
        if (element.classList.contains('service__expanded')) {
            element.classList.remove('service__expanded');
        }
        loadServiceHTML(element, false);
    }, COLLAPSE_DURATION);

    setTimeout(() => {
        fadeInElement(element.querySelector('.service__wrapper'));
        element.classList.remove('service__expanded-loaded');
        
        const listEls = element.querySelectorAll('.service__list-item');
        slideInListElements(listEls);
    }, FADE_OUT_DURATION);
}

function loadServiceHTML(element, isVisible) {
    const serviceData = services[element.getAttribute('serviceName')].short;
    
    // Use DocumentFragment for better performance
    const fragment = document.createDocumentFragment();
    const contentDiv = document.createElement('div');
    contentDiv.className = 'service__content';
    
    contentDiv.innerHTML = `
        <div class="service__wrapper ${isVisible ? '' : 'invisible'}">
            <div class="service__title">
                <h3 class="subtitle">${serviceData.name}</h3>
            </div>
            <div class="service__picture">
            <picture class="service__picture-img">
                <source
                    srcset="${images[element.getAttribute('serviceName')]}?as=webp"
                    type="image/webp">
                <img class="service__picture-img"
                        src="${images[element.getAttribute('serviceName')]}" 
                        alt="${serviceData.alt}" 
                        loading="lazy">
            </picture>
            </div>
            <ul class="service__list">
                ${serviceData.description.map(createListItemHTML).join('')}
            </ul>
            <div class="service__more">
                <button class="service__more-btn btn btn__small">WIĘCEJ</button>
            </div>
        </div>`;
    
    fragment.appendChild(contentDiv);
    element.innerHTML = '';
    element.appendChild(fragment);
}

function loadServicesElements() {
    if (!servicesWrapperEl) return;

    const fragment = document.createDocumentFragment();
    
    Object.entries(services).forEach(([key, service], index) => {
        const newElement = document.createElement('div');
        newElement.className = 'service swiper-slide';
        newElement.setAttribute('serviceName', key);
        loadServiceHTML(newElement, true);
        fragment.appendChild(newElement);

        if (index === Object.keys(services).length - 1) {
            // Create clones using DocumentFragment
            const clonesFragment = document.createDocumentFragment();
            for (let i = 0; i < 3; i++) {
                const clone = newElement.cloneNode(true);
                clone.classList.add('hidden', 'invisible');
                clonesFragment.appendChild(clone);
            }
            fragment.appendChild(clonesFragment);
        }
    });

    servicesWrapperEl.appendChild(fragment);
}

export function collapseAllServices(slides) {
    slides.forEach(slide => {
        if (slide.classList.contains('service__expanded')) {
            loadServiceShort(slide);
        }
    });
}